<template>
  <div class="">
    <div class="d-flex mb-2">
      <span class="page-header-title">Customer Registration Details</span>
    </div>
    <CCard>
      <CCardBody class="">
        <button type="button" class="btn btn-outline-info btn-sm" @click="
          (e) => {
            this.$router.push('/customer');
          }
        ">
          Back
        </button>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Registered User Name
          </div>
          <div class="col-md-9">
            {{ customerObj.users ? (customerObj.users.name ? customerObj.users.name : "-") : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Registered User Email ID
          </div>
          <div class="col-md-9">
            {{ customerObj.users ? (customerObj.users.email ? customerObj.users.email : "-") : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Registered User Mobile Number
          </div>
          <div class="col-md-9">
            {{ customerObj.user_profile ? customerObj.registered_phone_no : "-" }}
          </div>
        </div>
        <br />
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Company Name
          </div>
          <div class="col-md-9">
            {{ customerObj.customer_name ? customerObj.customer_name : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Constitution
          </div>
          <div class="col-md-9">
            {{
                customerObj.constitution_name ? customerObj.constitution_name : "-"
            }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Machine Location Address
          </div>
          <div class="col-md-9">
            {{
                customerObj.customer_address ? customerObj.customer_address : "-"
            }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Contact Person Name
          </div>
          <div class="col-md-9">
            {{
                customerObj.customer_name_alias
                  ? customerObj.customer_name_alias
                  : "-"
            }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Postal Code
          </div>
          <div class="col-md-9">
            {{ customerObj.postal_code ? customerObj.postal_code : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            City
          </div>
          <div class="col-md-9">
            {{ customerObj.city ? customerObj.city : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            GSTIN
          </div>
          <div class="col-md-9">
            {{ customerObj.gstin_no ? customerObj.gstin_no : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Region
          </div>
          <div class="col-md-9">
            {{ customerObj.region_name ? customerObj.region_name : "-" }}
          </div>
        </div>

        <br />
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Ship To/Delivery Address
          </div>
          <div class="col-md-9">
            {{
                customerObj.customer_address1 ? customerObj.customer_address1 : "-"
            }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Contact Person Name
          </div>
          <div class="col-md-9">
            {{ customerObj.customer_name1 ? customerObj.customer_name1 : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Postal Code
          </div>
          <div class="col-md-9">
            {{ customerObj.postal_code1 ? customerObj.postal_code1 : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            City
          </div>
          <div class="col-md-9">
            {{ customerObj.city1 ? customerObj.city1 : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            GSTIN
          </div>
          <div class="col-md-9">
            {{ customerObj.gstin_no1 ? customerObj.gstin_no1 : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            GST ARN
          </div>
          <div class="col-md-9">
            {{ customerObj.gstarn_no1 ? customerObj.gstarn_no1 : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Region
          </div>
          <div class="col-md-9">
            {{ customerObj.region_name1 ? customerObj.region_name1 : "-" }}
          </div>
        </div>
        <br />
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Payment Method
          </div>
          <div class="col-md-9">
            {{ customerObj.payment_method ? customerObj.payment_method : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Tax Classification
          </div>
          <div class="col-md-9">
            {{
                customerObj.tax_classification_name
                  ? customerObj.tax_classification_name
                  : "-"
            }}
          </div>
        </div>
        <br />
        <div style="font-size: 24px; font-weight: bold">
          Personal Information:
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Contact Person Name
          </div>
          <div class="col-md-9">
            {{ customerObj.contact_person ? customerObj.contact_person : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Designation
          </div>
          <div class="col-md-9">
            {{
                customerObj.contact_person_designation
                  ? customerObj.contact_person_designation
                  : "-"
            }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Email ID
          </div>
          <div class="col-md-9">
            {{ customerObj.contact_email ? customerObj.contact_email : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Mobile Number
          </div>
          <div class="col-md-9">
            {{ customerObj.contact_no }}
          </div>
        </div>
        <br />
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Finance Head Name
          </div>
          <div class="col-md-9">
            {{
                customerObj.finance_head_name ? customerObj.finance_head_name : "-"
            }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Designation
          </div>
          <div class="col-md-9">
            {{
                customerObj.finance_designation
                  ? customerObj.finance_designation
                  : "-"
            }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Email ID
          </div>
          <div class="col-md-9">
            {{ customerObj.finance_email ? customerObj.finance_email : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Mobile Number
          </div>
          <div class="col-md-9">
            {{ customerObj.finance_no }}
          </div>
        </div>
        <br />
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            MD/partner/Owner/Principal Officer
          </div>
          <div class="col-md-9">
            {{ customerObj.principal_name ? customerObj.principal_name : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Designation
          </div>
          <div class="col-md-9">
            {{
                customerObj.principal_designation
                  ? customerObj.principal_designation
                  : "-"
            }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Email ID
          </div>
          <div class="col-md-9">
            {{ customerObj.principal_email ? customerObj.principal_email : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Mobile Number
          </div>
          <div class="col-md-9">
            {{ customerObj.principal_no }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Fax Number
          </div>
          <div class="col-md-9">
            {{ customerObj.principal_fax ? customerObj.principal_fax : "-" }}
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Tel Number
          </div>
          <div class="col-md-9">
            {{ customerObj.principal_tel ? customerObj.principal_tel : "-" }}
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div style="font-size: 16px; font-weight: bold" class="col-md-3">
            Attachments
          </div>
          <div class="col-md-9 customer-image">

            <em class="fa fa-download fa-2x cursor mr-2" v-b-tooltip.hover title="Download Attachment 1"
              v-on:click="downloadAttachemnt(customerObj.attachment1)" v-if="customerObj.attachment1"></em>

            <em class="fa fa-download fa-2x cursor mr-2" v-b-tooltip.hover title="Download Attachment 2"
              v-on:click="downloadAttachemnt(customerObj.attachment2)" v-if="customerObj.attachment2"></em>

            <em class="fa fa-download fa-2x cursor mr-2" v-b-tooltip.hover title="Download Attachment 3"
              v-on:click="downloadAttachemnt(customerObj.attachment3)" v-if="customerObj.attachment3"></em>


            <div>
              <div v-if="this.customerObj && isAttachmentAvailable">
                {{ "-" }}
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { CUSTOMER_DETAILS } from "@/store/actions.type";
import _ from "lodash";
export default {
  name: "UserDetails",
  components: {},
  data() {
    return {
      rolesData: [],
      customerID: 0,
      customerObj: {
        name: "",
        email: "",
        id: "",
        status: "",
        city: "",
        company: "",
        company_address: "",
        company_name: "",
        designation: "",
        country: "",
        state: "",
        postal_code: "",
        phone_no: "",
        country_code: "",
        region_name: "",
        contact_no: "",
        contact_country_code: "",
        finance_no: "",
        finance_country_code: "",
        principal_no: "",
        principal_country_code: ""

      },
      isAttachmentAvailable: false,
    };
  },
  methods: {
    /**
     * @method getCustomerDetails
     * @description get customer details
     * @author @Darshan_Katthige_R
     */
    async getCustomerDetails() {
      if (this.customerID) {
        this.isCustomerDetailsLoading = this.$loading.show();
        await this.$store
          .dispatch(CUSTOMER_DETAILS, {
            id: this.customerID,
          })
          .then((data) => {
            if (data.success) {
              let detailsDataObj = _.get(data, "data.model", [])
              this.customerObj = detailsDataObj;
              if (
                this.customerObj.attachment3 == null &&
                this.customerID.attachment2 == null &&
                this.customerObj.attachment1 == null
              ) {
                this.isAttachmentAvailable = true;
              } else {
                this.isAttachmentAvailable = false;
              }
              this.customerObj.registered_phone_no =
                detailsDataObj.user_profile != null
                  ? detailsDataObj.user_profile.phone_no != null ?
                    detailsDataObj.user_profile.phone_no
                    : ""
                  : "";
              this.customerObj.registered_country_code =
                detailsDataObj.user_profile != null
                  ? detailsDataObj.user_profile.country_code != null ?
                    detailsDataObj.user_profile.country_code
                    : "-"
                  : "-";
              this.customerObj.registered_phone_no =
                this.customerObj.registered_country_code + " " + this.customerObj.registered_phone_no;


              this.customerObj.contact_no =
                detailsDataObj.contact_no != null
                  ? detailsDataObj.contact_no
                  : "";
              this.customerObj.contact_country_code =
                detailsDataObj.contact_country_code != null
                  ? detailsDataObj.contact_country_code
                  : "-";
              this.customerObj.contact_no =
                this.customerObj.contact_country_code + " " + this.customerObj.contact_no;

              this.customerObj.finance_no =
                detailsDataObj.finance_no != null
                  ? detailsDataObj.finance_no
                  : "";
              this.customerObj.finance_country_code =
                detailsDataObj.finance_country_code != null
                  ? detailsDataObj.finance_country_code
                  : "-";
              this.customerObj.finance_no =
                this.customerObj.finance_country_code + " " + this.customerObj.finance_no;

              this.customerObj.principal_no =
                detailsDataObj.principal_no != null
                  ? detailsDataObj.principal_no
                  : "";
              this.customerObj.principal_country_code =
                detailsDataObj.principal_country_code != null
                  ? detailsDataObj.principal_country_code
                  : "-";
              this.customerObj.principal_no =
                this.customerObj.principal_country_code + " " + this.customerObj.principal_no;


              this.isCustomerDetailsLoading.hide();
            } else {
              this.$toast.removeAll();
              this.$toast.error("ID does not exist. ");
              this.isCustomerDetailsLoading.hide();
              this.$router.push({
                name: "customer",
              });
            }
            this.isCustomerDetailsLoading.hide();
          });
      }

    },
    downloadAttachemnt(file) {
      const downloadLink = document.createElement("a");
      let fileName = "CustomerRegistration";
      // if (file.split(/[/;]/)[1] != "") {
      //   fileName = "CustomerRegistration." + file.split(/[/;]/)[1];
      // } else {
      //   fileName = "CustomerRegistration";
      // }

      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  },
  async created() {
    this.customerID = this.$store.state.selectedCustomer || 0;
      //_.get(this.$route, "params.id", 0);
    this.getCustomerDetails();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('set', ['selectedCustomer', null]);
    next()
  },
};
</script>
<style>
.labelMinWidth {
  min-width: 140px;
}
</style>
